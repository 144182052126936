import { changeTab } from "../../../store/features/tabSlice";
import { useAppDispatch } from "../../../store/store";

interface Props {
  label: string;
  id: number;
  selected?: boolean;
}

const TabItem = (props: Props) => {
  const dispatch = useAppDispatch();
  const { id, label, selected = false } = props;
  return (
    <li role="tab" aria-selected={selected ? "true" : "false"}>
      <a href="#tabs" onClick={() => dispatch(changeTab(id))}>
        {label}
      </a>
    </li>
  );
};

export default TabItem;
