import { Button, Checkbox } from "react-winplaza-98";
import Window98 from "../Window98";
import "./index.css";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import WindowAddButton from "../../features/WindowAddButton";
import { addWindow, removeWindow } from "../../../store/features/windowSlice";
import TOC from "../../features/TOC";
import FavoritesList from "../FavoritesList";
import TabList from "../../TabList";
import { ITab } from "../../../types";

const MainWindow = () => {
  const windows = useAppSelector((state) => state.window.windows);
  const dispatch = useAppDispatch();

  const thingsILove = [
    ["Играть в компик (сингловые эрпоге)", true],
    ["Играть в кастомочки в Дота 2", true],
    ["Ходить на РАБоту", false],
    ["Ходить в кино", true],
    ["Плотнейше хавать", true],
    ["Пить пиво и всякие коктейли (редко)", true],
    ["Тосковать", false],
    ["Гулять (если не сильно холодно)", true],
    ["Троллировать лалок", true],
    ["Котиков и кошечек", true],
    ["Современный КАЛьянный реп", false],
    ["Атмосферик БМ", true],
  ];

  const projectTabs: ITab[] = [
    {
      imgsrc: "/projects/alexmorph.jpg",
      label: "Алекс Морфеус",
      text: "Мой ютуб канал, где я снимал всякие постироничные видосы и легендарный сериал Листья Лжи.",
    },
    {
      imgsrc: "/projects/tests.jpg",
      label: "Дегенеративные тексты",
      text: "Мой первый вкат в паблики ВКонтакте. Большое спасибо моему корешу Антону и паблику Вьетнамские флешбеки на каждый день за то что помогли мне поначалу продвинуться.",
    },
    {
      label: "Бурбздум",
      links: [{ link: "https://vk.com/degtests", label: "Ссылка Вконтакте" }],
      imgsrc: "/projects/bzdum.jpg",
      text: "Построенный на костях Дегенеративных тестов паблик высмеивающий думерков и русскую тоску. Породил такие мемы как: Бздумер, Буеракус, 6:00, Жгите кнопки..., Слоник прух, Пример ну и Бздумерфесты",
    },
    { label: "Двач 3", text: "Подпишись", imgsrc: "/projects/dvach3.jpg" },
    {
      imgsrc: "/projects/csserv.jpg",
      label: "Сервер для CSS",
      text: "Кастомный сервер для Counter Strike: Source, созданный в той самой Adidas-васянской эстетике. Позволил многим молодым челикам ощутить ТУ САМУЮ атмосферу CSS",
    },
    {
      label: "Бздумерфесты",
      imgsrc: "/projects/fest.jpg",
      text: "Концерты созданные как альтернатива Doomer фестам. Главные отличия: несерьезность происходящего, свободный вход и НЕ думерская музыка. С 2022го по май 2023го было проведено 4 штуки.",
    },
    {
      imgsrc: "/projects/wiki.jpg",
      label: "Викитоск",
      text: "Пародийная вики про форсы околобздумерского интернета на которую я забил.",
    },
    {
      label: "Прочие",
      text: "Тут будет список всяких мелочных приколов, про которые и нечего сказать.",
    },
  ];

  return (
    <>
      <Window98
        zindex={1}
        label="Колобайная страница"
        cssId="main-window"
        id="mainWnd"
        bodyClass="window-body main"
        position={{ x: 0, y: 0 }}
        nonCloseable
        isDragable={false}
      >
        <h4>Добро пожаловать!</h4>
        <p>
          Привет! И добро пожаловать на мою домашнюю страницу. Тут вы можете
          узнать обо мне и всякие полезные ссылки. Если вы хотите попасть в
          определенный раздел сайта, то используйте оглавление.
        </p>
        <Button
          onClick={() =>
            dispatch(
              addWindow({
                label: "Оглавление",
                children: <TOC />,
              })
            )
          }
        >
          Показать оглавление
        </Button>
        <h4>Обо мне:</h4>
        <p>
          Меня зовут Колобай. Я лютый геймер и приколист. Работаю frontend
          разработчиком и в свободное время веду смешные группы вконтакте,
          снимаю видосы, стримлю ну и играю в игоры. Еще я очень люблю хавать,
          смотреть кино ну и все такое.
        </p>
        <p>
          Давайте я просто вкратце покажу список того, что я люблю, а что не
          очень)
        </p>

        <FavoritesList things={thingsILove} />
        <p>
          Вам наверное интересно - "Как же выглядит такой крутой и
          разносторонний молодой человек?"
        </p>
        <p>
          На Ваш вопрос у меня ответ имеется. Просто нажмите на кнопку,
          расположенную ниже:
        </p>
        <Button
          onClick={() =>
            dispatch(
              addWindow({
                label: "Мой жбан",
                children: <img src="/pixel.jpg" className="fit-picture" />,
              })
            )
          }
        >
          Показать меня
        </Button>
        <h4>Мои проекты</h4>
        <TabList tabs={projectTabs} />
      </Window98>

      {windows.map((wnd) => (
        <Window98
          zindex={wnd.zindex}
          id={wnd.id}
          label={wnd.label}
          position={wnd.position}
          isDragable={true}
          children={wnd.children}
          nonCloseable={wnd.nonCloseable}
          key={wnd.id}
          isPositionPercent={wnd.isPositionPercent}
        ></Window98>
      ))}
    </>
  );
};

export default MainWindow;
