import { useAppDispatch } from "../../../store/store";
import { removeWindow } from "../../../store/features/windowSlice";
interface Props {
  id: string;
}

const WindowCloseButton = (props: Props) => {
  const id = props.id;
  const dispatch = useAppDispatch();
  return (
    <button
      aria-label="Close"
      onClick={() => dispatch(removeWindow({ id: id }))}
    ></button>
  );
};

export default WindowCloseButton;
