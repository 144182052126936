import { configureStore } from "@reduxjs/toolkit";
import { windowSlice } from "./features/windowSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { tabSlice } from "./features/tabSlice";
// ...

export const store = configureStore({
  reducer: {
    window: windowSlice.reducer,
    tab: tabSlice.reducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
