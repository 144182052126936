const TOC = () => {
  return (
    <ul className="tree-view">
      <li>
        Оглавление
        <ul>
          <li>
            <a href="https://дибил.рф#about">Обо мне</a>
          </li>
          <details open>
            <summary>
              <a href="https://дибил.рф#projects">Мои проекты</a>
            </summary>
            <ul>
              <li>Алекс Морфеус</li>
              <li>Дегенеративные тесты</li>
              <li>Бурбздум</li>
              <li>Сервер для КСС</li>
              <li>Бздумерфесты</li>
              <li>Викитоск</li>
            </ul>
          </details>
          <li>Характеристики моего компика</li>
          <li>Мои социальные сети</li>
        </ul>
      </li>
    </ul>
  );
};

export default TOC;
