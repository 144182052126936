import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface TabsState {
  tabs: string[];
  currentTab: number;
}

const initialState: TabsState = { tabs: ["ass", "bebra"], currentTab: 0 };

export const tabSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    changeTab(state, action: PayloadAction<number>) {
      state.currentTab = action.payload;
    },
  },
});

export default tabSlice.reducer;

export const { changeTab } = tabSlice.actions;
