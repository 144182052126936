import "./index.css";
const BootConsole = () => {
  return (
    <div>
      <p className="booter-text">{"Michaelsoft Binbows [Version 1.3.3.7]"}</p>
      <p className="booter-text">
        {"(c) Skufosoft corporation. All rights reverted."}
      </p>
      <p className="booter-text">{"Initializing system...Done"}</p>
      <p className="booter-text">
        {"C:\\"} <p id="showMe">_</p>
      </p>
    </div>
  );
};

export default BootConsole;
