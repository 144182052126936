import { ReactNode } from "react";
import WindowHeader from "../WindowHeader";
import "./index.css";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useAppDispatch } from "../../../store/store";
import { placeOnTop } from "../../../store/features/windowSlice";

export interface Window98Props {
  position: { x: number; y: number };
  isVisible?: boolean;
  isDragable?: boolean;
  label: string;
  id: string;
  cssId?: string;
  children: ReactNode;
  bodyClass?: string;
  nonCloseable?: boolean;
  zindex: number;
  isPositionPercent?: boolean;
}

const Window98 = (props: Window98Props) => {
  const dispatch = useAppDispatch();
  const {
    label,
    id,
    cssId,
    children,
    bodyClass = "window-body",
    nonCloseable = false,
    isDragable = true,
    zindex,
    position,
    isPositionPercent = false,
  } = props;

  const units = isPositionPercent ? "%" : "px";
  const styleObj = {
    zIndex: zindex,
    left: position?.x + units,
    top: position?.y + units,
  };

  const eventHandler = (e: DraggableEvent, data: DraggableData) => {
    dispatch(placeOnTop({ id: id }));
  };

  return (
    <Draggable
      handle=".title-bar-text"
      onStart={eventHandler}
      disabled={!isDragable}
    >
      <div
        className={`window ${isDragable ? "float" : ""}`}
        id={cssId}
        style={isDragable ? styleObj : { zIndex: zindex }}
        onClick={() => dispatch(placeOnTop({ id: id }))}
      >
        <WindowHeader label={label} nonCloseable={nonCloseable} id={id} />
        <div className={bodyClass}>
          <p> {children}</p>
        </div>
      </div>
    </Draggable>
  );
};

export default Window98;
