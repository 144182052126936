import React from "react";
import TabContent from "../layout/TabContent";
import TabItem from "../features/TabItem";
import { ITab } from "../../types";
import { useAppSelector } from "../../store/store";

interface Props {
  tabs: ITab[];
}

const TabList = (props: Props) => {
  const selectedTab = useAppSelector((state) => state.tab);
  const { tabs } = props;
  return (
    <>
      <menu role="tablist">
        {tabs.map((elem, index) => (
          <TabItem
            key={"tabb" + index}
            id={index}
            label={elem.label}
            selected={selectedTab.currentTab === index}
          ></TabItem>
        ))}
      </menu>
      <TabContent
        links={tabs[selectedTab.currentTab].links}
        text={tabs[selectedTab.currentTab].text}
        imgsrc={tabs[selectedTab.currentTab].imgsrc || "/projects/blank.jpg"}
        label={tabs[selectedTab.currentTab].label}
      />
    </>
  );
};

export default TabList;
