import { Button } from "react-winplaza-98";
import { ITab } from "../../../types";

const TabContent = (props: ITab) => {
  const { text, imgsrc, label, links } = props;
  return (
    <div className="window" role="tabpanel">
      <div className="window-body">
        <h5 style={{ fontSize: "1.2rem", margin: 0 }}>{label}</h5>
        <img src={imgsrc} style={{ width: "50%" }} alt="no img" />
        <p>{text}</p>
        {links &&
          links.map((elem, index) => (
            <Button
              key={"linkbtn" + index}
              onClick={() => window.open(elem.link, "_blank")}
            >
              {elem.label}
            </Button>
          ))}
      </div>
    </div>
  );
};

export default TabContent;
