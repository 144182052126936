import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ReactNode } from "react";
import TOC from "../../components/features/TOC";
import { Window98Props } from "../../components/layout/Window98";

interface Windows98State {
  windows: Window98Props[];
  zindexGlobal: number;
  positionOffset: number;
  positionOffsetX: number;
}

const initialState: Windows98State = {
  windows: [
    {
      position: { x: 60, y: 60 },
      isPositionPercent: true,
      label: "Котяра лмао",
      id: "kotyara",
      nonCloseable: true,
      zindex: 900,
      children: (
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", width: "20rem" }}
          src="/maxwell.gif"
          alt="котяра.гиф"
        />
      ),
    },
    {
      position: { x: 70, y: 15 },
      label: "Оглавление",
      id: "TOC1",
      isPositionPercent: true,
      nonCloseable: false,
      zindex: 901,
      children: <TOC />,
    },
  ],
  zindexGlobal: 1,
  positionOffset: 0,
  positionOffsetX: 0,
};

export const windowSlice = createSlice({
  name: "windows",
  initialState,
  reducers: {
    addWindow: (
      state,
      action: PayloadAction<{
        label: string;
        children: ReactNode;
        position?: { x: number; y: number };
      }>
    ) => {
      state.zindexGlobal += 1;
      const wndId = _.uniqueId("wnd");
      if (state.windows.length < 1) {
        state.positionOffset = 0;
      }
      state.windows.push({
        id: wndId,
        position: action.payload.position
          ? { x: action.payload.position.x, y: action.payload.position.y }
          : {
              x: 300 + state.positionOffset + state.positionOffsetX,
              y: 200 + state.positionOffset,
            },
        label: action.payload.label,
        children: action.payload.children,
        zindex: state.zindexGlobal,
      });
      if (state.positionOffset > 200) {
        state.positionOffset = 0;
        state.positionOffsetX -= 15;
      }
      state.positionOffset += 15;
    },
    removeWindow: (state, action: PayloadAction<{ id: string }>) => {
      state.windows = state.windows.filter((elem) => {
        return elem.id !== action.payload.id;
      });
    },
    placeOnTop: (state, action: PayloadAction<{ id: string }>) => {
      const elem = state.windows.find((elem) => elem.id === action.payload.id);
      if (elem) {
        state.zindexGlobal += 1;
        elem.zindex = state.zindexGlobal;
      }
    },
  },
});

export default windowSlice.reducer;

export const { addWindow, removeWindow, placeOnTop } = windowSlice.actions;
