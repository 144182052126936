import "98.css";
import MainWindow from "./components/layout/MainWindow";
import { useState } from "react";
import BootConsole from "./components/features/BootConsole";

function App() {
  const [isBooted, setIsBooted] = useState(false);

  setTimeout(() => setIsBooted(true), 1500);
  document.body.style.backgroundColor = isBooted ? "#008080" : "black";
  return (
    <div className="App">{isBooted ? <MainWindow /> : <BootConsole />}</div>
  );
}

export default App;
