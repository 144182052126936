import WindowCloseButton from "../../features/WindowCloseButton";

interface Props {
  label: string;
  nonCloseable: boolean;
  id: string;
}

const WindowHeader = (props: Props) => {
  const { label, nonCloseable, id } = props;
  return (
    <div className="title-bar">
      <div
        className="title-bar-text"
        style={{ width: "100%", cursor: "grab", margin: 0 }}
      >
        {label}
      </div>
      <div className="title-bar-controls">
        {nonCloseable || <WindowCloseButton id={id} />}
      </div>
    </div>
  );
};

export default WindowHeader;
