import { Checkbox } from "react-winplaza-98";

interface Props {
  things: (string | boolean)[][];
}

const FavoritesList = (props: Props) => {
  const { things } = props;

  return (
    <ul>
      {things.map((thing, index) => (
        <li>
          <Checkbox
            name={`checkbox${index}`}
            label={thing[0] as string}
            checked={thing[1] as boolean}
          />
        </li>
      ))}
    </ul>
  );
};

export default FavoritesList;
